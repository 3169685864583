import PimplesTemplate from '@/modules/questionnaire/components/steps/photo-analysis/pimples/pimples/PimplesTemplate';

import {
  BOOLEAN,
  COVERAGE,
  ACNE_FREQUENCY,
  ACNE_DURATION
} from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/Pimples/Pimples/PimplesTemplate',
  component: PimplesTemplate
};

const createStory = props => () => ({
  components: { PimplesTemplate },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><pimples-template v-bind="$options.storyProps" /></div>'
});

export const Base = createStory({
  proneToAcne: BOOLEAN.YES,
  proneToAcneAiSelected: BOOLEAN.YES,
  gender: 'female',
  acneDetailQuestionsVisible: BOOLEAN.YES,
  facialAcneCoverage: COVERAGE.SMALL,
  facialAcneCoverageAiSelected: COVERAGE.MEDIUM,
  acneDiscomfortFrequency: ACNE_FREQUENCY.UNDER_A_WEEK,
  firstAcneOccurrence: ACNE_DURATION.UNDER_A_YEAR
});
